import { Client, Exchange, fetchExchange } from '@urql/core';

import { cacheExchange as makeCacheExchange } from '@urql/exchange-graphcache';
import makeRetryExchange from './exchanges/retry';
import monitorExchange from './exchanges/monitor';
// import { devtoolsExchange } from '@urql/devtools';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import schema from '../../cms/graphql/introspection.json';

const cacheExchange = makeCacheExchange({
  keys: new Proxy(
    {
      NavigationItemRelatedData: () => null
    },
    {
      get(_target, prop, _receiver) {
        if (prop === 'NavigationItemRelatedData') {
          return () => null;
        }
        return (data: any) => data.id || data._id;
      }
    }
  ),
  schema: process.env.GATSBY_ACTIVE_ENV === 'development' ? schema : undefined
});

const exchanges: Exchange[] = [
  monitorExchange(),
  requestPolicyExchange({}),
  cacheExchange,
  // Note the position of the retryExchange - it should be placed prior to the
  // fetchExchange and after the cacheExchange for it to function correctly
  makeRetryExchange(),
  fetchExchange
];

// NOTE: Webpack is not clever enough to drop devtoolsExchange
// if (process.env.GATSBY_URQL_DEBUG === 'true') {
//   // eslint-disable-next-line no-console
//   console.info('Running URQL in debug mode');
//   exchanges.unshift(devtoolsExchange);
// }

export const cmsClient = new Client({
  url: process.env.GATSBY_CMS_GRAPHQL_ENDPOINT as string,
  exchanges
});

// if (client.subscribeToDebugTarget) {
//   client.subscribeToDebugTarget(event => {
//     if (event.source === 'cacheExchange') {
//       console.log('cache event: ', event);
//     }
//   });
// }
