import * as Sentry from '@sentry/browser';
import * as Schema from '../../backend/graphql/gql';
import * as SchemaCms from '../../cms/graphql/gql';
import { DisruptorServiceWorkerAsync, ServiceRequestKey } from '../types';
import { backendClient } from './urqlClient';
import { cmsClient } from './urqlClientCms';
import type { DocumentNode } from '@urql/core/dist/urql-core-chunk';
import type { Subscription } from 'wonka';
import isbot from 'isbot';
import * as Comlink from 'comlink';
import generateId from 'lib/id';
import { workerEventTarget } from './exchanges/events';
import { parseResult } from './lib/ops';
import { AppEventName } from 'lib/events/contracts';
import { DisruptorCmsRequestKey } from '@svelte/service/cms/types';

function isBot() {
  return isbot(navigator.userAgent);
}

const SENTRY_ENABLED = process.env.GATSBY_SENTRY_DISABLED !== 'true';
const environment =
  process.env.GATSBY_SENTRY_ENVIRONMENT ||
  process.env.CF_PAGES_BRANCH ||
  'production';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  tunnel: process.env.GATSBY_SENTRY_TUNNEL,
  enabled: SENTRY_ENABLED,
  environment,
  release: process.env.CF_PAGES_COMMIT_SHA,
  async beforeSend(event, _hint) {
    if (isBot()) {
      return null;
    }

    return event;
  }
});

const documentMap: Record<ServiceRequestKey, string | DocumentNode> = {
  addItemToCart: Schema.AddItemToCartDocument,
  allProducts: Schema.AllProductsDocument,
  chatUser: Schema.ChatUserDocument,
  checkoutUserData: Schema.CheckoutUserDataDocument,
  confirmEmailAddress: Schema.ConfirmEmailAddressDocument,
  confirmNewsletterDiscount: Schema.ConfirmNewsletterDiscountDocument,
  confirmNewsletterSubscription: Schema.ConfirmNewsletterSubscriptionDocument,
  editCartItemQty: Schema.EditItemQtyDocument,
  exchangeLoginMagicLink: Schema.ExchangeLoginMagicLinkDocument,
  exchangeLoginTokenDirect: Schema.ExchangeLoginTokenDirectDocument,
  logOut: Schema.LogOutDocument,
  openSupportTicket: Schema.OpenSupportTicketDocument,
  openSupportTicketAndSubscribeNewsletter:
    Schema.OpenSupportTicketAndSubscribeNewsletterDocument,
  orderConfirmation: Schema.OrderConfirmationDocument,
  orders: Schema.OrdersDocument,
  remoteCartInvoiceState: Schema.RemoteCartInvoiceStateDocument,
  removeCartItem: Schema.RemoveItemDocument,
  requestMagicLink: Schema.RequestMagicLinkDocument,
  session: Schema.SessionDocument,
  setAdtractionAttribution: Schema.SetAdtractionAttributionDocument,
  setCartCustomer: Schema.SetCartCustomerDetailsDocument,
  setCartCustomerAndSubscribe:
    Schema.SetCartCustomerDetailsAndSubscribeDocument,
  stripeClientSecret: Schema.StripeClientSecretDocument,
  subscribeToNewsletter: Schema.SubscribeToNewsletterDocument,
  userEmail: Schema.CurrentEmailDocument
};

const comlinkSubs: Record<string, Subscription> = {};

const documentCmsMap: Record<DisruptorCmsRequestKey, string | DocumentNode> = {
  navigationMenu: SchemaCms.NavigationMenuDocument
};

const getClient = (key: ServiceRequestKey | DisruptorCmsRequestKey) => {
  // @ts-expect-error
  return documentCmsMap[key] ? cmsClient : backendClient;
};

const getDocument = (key: ServiceRequestKey | DisruptorCmsRequestKey) => {
  // @ts-expect-error
  return documentCmsMap[key] || documentMap[key];
};

export const graphqlAsyncInterface: DisruptorServiceWorkerAsync = {
  queryPromise(request, context) {
    return getClient(request.key)
      .query(documentMap[request.key], request.variables, context)
      .toPromise()
      .then(parseResult);
  },
  query(request, callback, context) {
    const id = generateId();
    comlinkSubs[id] = getClient(request.key)
      .query(getDocument(request.key), request.variables, context)
      .subscribe(result => {
        callback(parseResult(result));
      });

    return Promise.resolve(id);
  },
  mutation(request, callback, context) {
    const id = generateId();
    comlinkSubs[id] = backendClient
      .mutation(documentMap[request.key], request.variables, context)
      .subscribe(result => callback(parseResult(result)));

    return Promise.resolve(id);
  },
  mutationPromise(request, context) {
    return backendClient
      .mutation(documentMap[request.key], request.variables, context)
      .toPromise()
      .then(parseResult);
  },
  graphqlSubscription(request, callback, context) {
    const id = generateId();
    comlinkSubs[id] = backendClient
      .subscription(documentMap[request.key], request.variables, context)
      .subscribe(result => {
        callback(parseResult(result));
      });

    return Promise.resolve(id);
  },
  handleWindowVisibilityChange: visible => {
    const payload = new CustomEvent('visibilitychange', {
      detail: { visible }
    });
    self.dispatchEvent(payload);
  },
  async handleDisruptorEvent(event) {
    Sentry.addBreadcrumb({
      message: event.name,
      data: event
    });

    switch (event.name) {
      case AppEventName.LoggedInAnotherTab:
        await backendClient
          .mutation(Schema.AuthenticateDocument, {})
          .toPromise();
        break;
      default:
        break;
    }
  },
  unsubscribe(id: string) {
    const sub = comlinkSubs[id];
    sub && sub.unsubscribe();
  },
  listenToWorkerEvent(listener) {
    workerEventTarget.subscribeToAll(listener);
  },
  listenToWorkerErrors(listener) {
    workerEventTarget.subscribeToAllErrors(listener);
  }
};

Comlink.expose(graphqlAsyncInterface);
