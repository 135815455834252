class AuthenticationState {
  private _authenticated = false;

  get authenticated() {
    return this._authenticated;
  }

  authenticate() {
    this._authenticated = true;
  }

  reset() {
    this._authenticated = false;
  }
}

const authenticationState = new AuthenticationState();

export default authenticationState;
