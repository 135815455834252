import { canUseDom } from '../util';
import { Writable } from 'svelte/store';
import { BatteryState, ConnectionState, Dimensions } from './state';

function batteryStateFromManager(manager: BatteryManager): BatteryState {
  return {
    charging: manager.charging,
    chargingTime: manager.chargingTime,
    dischargingTime: manager.dischargingTime,
    // ranges 0-1
    level: manager.level * 100
  };
}

export function getWindowDimensions() {
  if (!canUseDom()) {
    return { width: 0, height: 0 };
  }

  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export class DeviceStateManager {
  private running = false;
  private deviceObservers: Array<() => void> = [];

  constructor(
    readonly connectionStore: Writable<ConnectionState | undefined>,
    readonly batteryStore: Writable<BatteryState | undefined>,
    readonly dimensionsStore: Writable<Dimensions>
  ) {}

  init() {
    if (this.running || !canUseDom()) {
      return;
    }

    this.running = true;

    const handleResize = () => {
      this.dimensionsStore.set(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    this.deviceObservers.push(() =>
      window.removeEventListener('resize', handleResize)
    );

    if (window.navigator.getBattery) {
      window.navigator.getBattery().then(batteryManager => {
        // Init value in store, as it will be undefined by default
        this.batteryStore.set(batteryStateFromManager(batteryManager));

        const chargingListener = () => {
          this.batteryStore.set(batteryStateFromManager(batteryManager));
        };

        const levelListener = () => {
          this.batteryStore.set(batteryStateFromManager(batteryManager));
        };

        batteryManager.addEventListener('chargingchange', chargingListener);
        batteryManager.addEventListener('levelchange', levelListener);
        this.deviceObservers.push(() =>
          batteryManager.removeEventListener('chargingchange', chargingListener)
        );
        this.deviceObservers.push(() =>
          batteryManager.removeEventListener('levelchange', levelListener)
        );
      });
    }

    if (window.navigator.connection) {
      const connectionListener = (e: Event) => {
        const connection = e.currentTarget as NetworkInformation;
        this.connectionStore.set({
          type: connection.type,
          effectiveType: connection.effectiveType,
          downlinkMax: connection.downlinkMax,
          downlink: connection.downlink,
          rtt: connection.rtt,
          saveData: connection.saveData
        });
      };
      window.navigator.connection.addEventListener(
        'change',
        connectionListener
      );
      this.deviceObservers.push(
        () =>
          window.navigator.connection?.removeEventListener(
            'change',
            connectionListener
          )
      );
    }
  }

  stop() {
    this.running = false;
    for (const unsubscribe of this.deviceObservers) {
      unsubscribe();
    }
    this.deviceObservers = [];
  }
}
