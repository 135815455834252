import { loggers } from 'lib/log';
import { Cache } from '@urql/exchange-graphcache';
import * as GqlSchema from '../../../backend/graphql/gql';
import { gql } from '@urql/core';

const log = loggers.service;

export function getCachedSessionUserOrThrow(
  cache: Cache
): GqlSchema.SessionUserFragment {
  const cachedFragment = cache.readFragment<GqlSchema.SessionUserFragment>(
    // @ts-expect-error strings seem to work
    GqlSchema.SessionUserFragmentDoc,
    {}
  );

  if (!cachedFragment) {
    throw Error('SessionUser not found in urql cache');
  }

  return cachedFragment;
}

type UpdateSessionEmailArgs = {
  cache: Cache;
  email: string;
  acceptedPolicies?: boolean;
};

export function updateSessionEmail({
  cache,
  email,
  acceptedPolicies
}: UpdateSessionEmailArgs) {
  const cachedUser = getCachedSessionUserOrThrow(cache);

  const fragment = gql`
    fragment _ on SessionUser {
      id
      acceptedPolicies
      email
    }
  `;

  if (cachedUser.profile || cachedUser.cart?.discount) {
    log.info('Not changing immutable email after mutation', {
      email,
      cachedUser
    });
    return;
  }

  cache.writeFragment(fragment, {
    id: cachedUser.id,
    acceptedPolicies,
    email
  });
}

// type CacheItem = GqlSchema.OrderDataFragment;

/**
 * HACK: Cache has undocumented data.records
 * See: https://github.com/urql-graphql/urql/discussions/2523
 */
// type CacheWithData = Cache & {
//   data: {
//     records: {
//       base: Map<string, CacheItem>;
//     };
//   };
// };

// type CacheFilter<T extends CacheItem> = (item: CacheItem) => item is T;

// const isOrder = (item: CacheItem): item is GqlSchema.OrderDataFragment =>
//   item.__typename === 'Order';

// function getCacheData(cache: Cache) {
//   return [...(cache as any as CacheWithData).data.records.base.values()];
// }

// function getCacheItems<T extends CacheItem>(
//   cache: Cache,
//   filter: CacheFilter<T>
// ): Array<T> {
//   const cacheData = getCacheData(cache);
//   return cacheData.filter(filter);
// }
