import { DisruptorExtensions } from '@svelte/service/backend/graphql/extensions';
import { RetryExchangeOptions, retryExchange } from '@urql/exchange-retry';

const retryOptions: RetryExchangeOptions = {
  initialDelayMs: 500,
  maxDelayMs: 10000,
  randomDelay: true,
  maxNumberAttempts: 3,
  retryIf: (error, operation) => {
    /**
     * Retries within subscriptions should be done with ws client,
     * which is not implemented yet.
     *
     * There are two reasons why not:
     * - A retry would create a new ws connection
     * - If there's an error, the wonka observer gets replaced,
     *   and the subscriber never gets to see that error
     */
    const isSubscription = operation.kind === 'subscription';
    const validationErrors = new DisruptorExtensions(error).badUserInput();
    return !isSubscription && !validationErrors;
  }
};

const makeRetryExchange = () => retryExchange(retryOptions);

export default makeRetryExchange;
