import { canUseDom } from '../util';
import { Readable, derived, writable } from 'svelte/store';
import { getWindowDimensions } from './deviceState';

// Parsed value of NetworkInformation
// for better logging and monitoring
export type ConnectionState = {
  readonly type?: ConnectionType;
  readonly effectiveType?: EffectiveConnectionType;
  readonly downlinkMax?: Megabit;
  readonly downlink?: Megabit;
  readonly rtt?: Millisecond;
  readonly saveData?: boolean;
};

export const connectionStore = writable<ConnectionState | undefined>(
  canUseDom() ? window.navigator.connection : undefined
);

export type BatteryState = {
  charging: boolean;
  chargingTime: number;
  dischargingTime: number;
  level: number;
};

export const lowBatteryStore = writable<BatteryState | undefined>();

export type DeviceState = {
  battery?: BatteryState;
  connection?: ConnectionState;
};

export const deviceState: Readable<DeviceState> = derived(
  [connectionStore, lowBatteryStore],
  ([connection, battery]) => {
    return {
      connection,
      battery
    };
  }
);

const slowConnectionTypes: EffectiveConnectionType[] = ['slow-2g', '2g', '3g'];

export const highSpeedConnection = derived(connectionStore, connection => {
  if (!connection) {
    return true;
  } else if (connection?.effectiveType) {
    return !slowConnectionTypes.includes(connection.effectiveType);
  } else {
    return true;
  }
});

export const enum Breakpoint {
  SM = 640,
  MD = 768,
  LG = 1024,
  XL = 1280,
  XXL = 1536
}

export const APP_BREAKPOINTS = [
  Breakpoint.SM,
  Breakpoint.MD,
  Breakpoint.LG,
  Breakpoint.XL,
  Breakpoint.XXL
];

export type Dimensions = {
  width: number;
  height: number;
};

export const windowDimensions = writable<Dimensions>(getWindowDimensions());
export const windowBreakpoint = derived(windowDimensions, ({ width }) => {
  let output = Breakpoint.SM;

  for (const breakPoint of APP_BREAKPOINTS) {
    if (width > breakPoint) {
      output = breakPoint;
    } else {
      return output;
    }
  }

  return output;
});

export const mediaAssetDataSaver = derived(
  [deviceState, highSpeedConnection, windowBreakpoint],
  ([{ connection, battery }, highSpeedConnection, windowBreakpoint]) => {
    return Boolean(
      connection?.saveData ||
        (battery?.level && battery.level < 15) ||
        (!highSpeedConnection && windowBreakpoint === Breakpoint.SM)
    );
  }
);
