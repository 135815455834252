import { GraphQLWebSocketClient } from './ws';
import { loggers } from 'lib/log';

const log = loggers.service;

export function makeWsClient(): Promise<GraphQLWebSocketClient> {
  return new Promise((resolve, reject) => {
    let isOpen = false;
    const socket = new WebSocket(
      process.env.GATSBY_GRAPHQL_ENDPOINT_SUBSCRIPTIONS as string,
      'graphql-transport-ws'
    );

    socket.addEventListener('error', event => {
      if (!isOpen) {
        const error = new Error('Unable to open websocket');
        log.error(error, { event });
        reject();
      }
    });

    const client: GraphQLWebSocketClient = new GraphQLWebSocketClient(
      socket,
      {}
    );

    socket.addEventListener('open', () => {
      isOpen = true;

      resolve(client);

      // TODO: what to reuse?
      // const client: GraphQLWebSocketClient = new GraphQLWebSocketClient(socket, {
      //   onAcknowledged: _p => {
      //     const proxiedSubscribe = client.subscribe.bind(client);
      //     client.subscribe = (document, subscriber, variables) => {
      //       const proxiedError = subscriber.error;
      //       subscriber.error = error => {
      //         error.response.extensions = new DisruptorExtensions(
      //           error.response.errors || []
      //         );
      //         return proxiedError && proxiedError.call(subscriber, error);
      //       };
      //       return proxiedSubscribe(document, subscriber, variables);
      //     };
      //     return Promise.resolve(resolve(client));
      //   }
      // });
    });
  });
}
