import { CacheExchangeOpts } from '@urql/exchange-graphcache';
import * as GqlSchema from '../../../backend/graphql/gql';
import { getCachedSessionUserOrThrow } from './ops';

export const cacheExchangeOptimistic: CacheExchangeOpts['optimistic'] = {
  logout(_args, cache): GqlSchema.LogOutMutation['logout'] {
    const cachedUser = getCachedSessionUserOrThrow(cache);

    const nextUser: GqlSchema.LogOutMutation['logout'] = {
      ...cachedUser,
      acceptedPolicies: false,
      cart: null,
      confirmedNewsletter: false,
      email: null,
      profile: null,
      subscribedToNewsletter: false,
      subscribedToNewsletterTime: null,
      orders: [],
      chatwootSignedId: ''
    };

    return nextUser;
  }
};
