import { DisruptorExtensions } from '@svelte/service/backend/graphql/extensions';
import { Exchange, mapExchange } from '@urql/core';
import { loggers } from 'lib/log';
import { getOperationName } from '../lib/ops';

const log = loggers.service;

const monitorExchange = (): Exchange =>
  mapExchange({
    onOperation(operation) {
      log.debug('Starting GraphQL operation', {
        name: getOperationName(operation),
        operation: operation as Record<string, any>
      });
    },
    onResult(result) {
      log.debug('GraphQL result', {
        name: getOperationName(result.operation),
        data: result.data
      });
    },
    onError(error, operation) {
      const extensions = new DisruptorExtensions(error);
      const badUserInput = extensions.badUserInput();
      const rateLimit = extensions.resourceExhausted();
      if (!badUserInput && !rateLimit) {
        log.error(error, {
          name: getOperationName(operation),
          extensions,
          operation
        });
      }
    }
  });

export default monitorExchange;
