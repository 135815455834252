import log, { LogLevelDesc, Logger } from 'loglevel';

import type { Event as SentryEvent } from '@sentry/react';
import { withColor } from './loglevel-color';
import withSentry from './loglevel-sentry';

const SENTRY_DISABLED = process.env.GATSBY_SENTRY_DISABLED === 'true';
const LOG_LEVEL = (process.env.GATSBY_LOG_LEVEL || 'info') as LogLevelDesc;

export function isLogEnabledForDebug(name: string): boolean {
  const debugModules = (process.env.GATSBY_DEBUG || '').split(',');
  return !!debugModules.some(x => x.match(name));
}

export interface WorkerError {
  error: Error;
  workerName: string;
  payload: Record<string, any>;
}

export interface LogOpts {
  capture: boolean;
  event?: SentryEvent;
}

export function isLogOpts(x: unknown): x is LogOpts {
  return !!x && typeof (x as Record<string, unknown>).capture === 'boolean';
}

export interface ScopedLogger extends Logger {
  /**
   * Output trace message to console.
   * This will also include a full stack trace
   *
   * @param msg any data to log to the console
   */
  trace(msg: string, data?: Record<string, unknown>): void;

  /**
   * Output debug message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  debug(msg: string, data?: Record<string, unknown>): void;

  /**
   * Output debug message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  log(msg: string, opts?: LogOpts, data?: Record<string, unknown>): void;
  log(msg: string, data?: Record<string, unknown>): void;

  /**
   * Output info message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  info(msg: string, opts?: LogOpts, data?: Record<string, unknown>): void;
  info(msg: string, data?: Record<string, unknown>): void;

  /**
   * Output warn message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  warn(msg: string, opts?: LogOpts, data?: Record<string, unknown>): void;
  warn(msg: string, data?: Record<string, unknown>): void;
  /**
   * Output error message to console including appropriate icons
   *
   * @param msg any data to log to the console
   */
  error(error: Error, data?: Record<string, unknown>): void;
}

export const scopedLog = (name: string): ScopedLogger => {
  const logger = log.getLogger(name);

  if (SENTRY_DISABLED) {
    withColor(logger);
    logger.setLevel(isLogEnabledForDebug(name) ? LOG_LEVEL : 'info');
  } else {
    withSentry(logger);
    logger.setLevel(LOG_LEVEL);
  }

  return logger;
};

export function setupGlobalLog(): void {
  if (SENTRY_DISABLED) {
    withColor(log);
  } else {
    withSentry(log);
  }

  log.setLevel(LOG_LEVEL);
}

export const loggers = {
  analytics: scopedLog('analytics'),
  ui: scopedLog('ui'),
  data: scopedLog('data'),
  event: scopedLog('event'),
  navigation: scopedLog('nav'),
  orders: scopedLog('orders'),
  service: scopedLog('service'),
  state: scopedLog('state')
};
