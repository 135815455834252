// source: https://dirask.com/posts/JavaScript-generate-unique-id-useful-in-web-browser-D9az0D

let counter = 0;

function renderCounter(base = 10) {
  const value = ++counter;
  return value.toString(base);
}

function renderTime(base = 10) {
  const value = Date.now();
  return value.toString(base);
}

function renderRandom(base = 10) {
  const value = Math.floor(1e16 + 9e16 * Math.random());
  return value.toString(base);
}

export default function generateId(base = 10, separator = '-'): string {
  return (
    renderCounter(base) +
    separator +
    renderTime(base) +
    separator +
    renderRandom(base)
  );
}
