import { CacheExchangeOpts } from '@urql/exchange-graphcache';
import { gql } from '@urql/core';
import { Variables } from '../../types';
import { getCachedSessionUserOrThrow, updateSessionEmail } from './ops';
import * as GqlSchema from '../../../backend/graphql/gql';

export const cacheExchangeUpdates: CacheExchangeOpts['updates'] = {
  Mutation: {
    confirmNewsletterSubscription(
      _result,
      args: Variables<'confirmNewsletterSubscription'>,
      cache,
      _info
    ) {
      updateSessionEmail({
        cache,
        email: args.confirmation.email,
        acceptedPolicies: true
      });

      const fragment = gql`
        fragment _ on SessionUser {
          id
          confirmedNewsletter
          subscribedToNewsletter
        }
      `;

      const cachedUser = getCachedSessionUserOrThrow(cache);

      cache.writeFragment(fragment, {
        id: cachedUser.id,
        confirmedNewsletter: true,
        subscribedToNewsletter: true
      });
    },
    openSupportTicket(
      _result,
      { enquiry }: Variables<'openSupportTicket'>,
      cache,
      _info
    ) {
      updateSessionEmail({
        cache,
        email: enquiry.email,
        acceptedPolicies:
          enquiry.acceptedPrivacyPolicy && enquiry.acceptedTermsAndConditions
      });
    },
    setCartCustomerDetails(
      _result,
      { details }: Variables<'setCartCustomer'>,
      cache,
      _info
    ) {
      updateSessionEmail({
        cache,
        email: details.email,
        acceptedPolicies:
          details.acceptedPrivacyPolicy && details.acceptedTermsAndConditions
      });
    },
    subscribeToNewsletter(
      _result,
      { subscription }: Variables<'subscribeToNewsletter'>,
      cache,
      _info
    ) {
      updateSessionEmail({
        cache,
        email: subscription.email,
        acceptedPolicies: true
      });

      const fragment = gql`
        fragment _ on SessionUser {
          id
          subscribedToNewsletter
          subscribedToNewsletterTime
        }
      `;

      const cachedUser = getCachedSessionUserOrThrow(cache);

      cache.writeFragment(fragment, {
        id: cachedUser.id,
        subscribedToNewsletter: true,
        subscribedToNewsletterTime: new Date().toISOString()
      });
    }
  },
  Subscription: {
    requestMagicLink(
      result: GqlSchema.RequestMagicLinkSubscription,
      args: Variables<'requestMagicLink'>,
      cache,
      _info
    ) {
      if (result.requestMagicLink.status === 'REQUESTED') {
        updateSessionEmail({
          cache,
          email: args.email,
          acceptedPolicies: true
        });
      }
    }
  }
};
