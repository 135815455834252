import type { OperationDefinitionNode } from '@0no-co/graphql.web';
import { gql, OperationResult, type Operation } from '@urql/core';
import { DisruptorServiceWorker, GraphQLOutput, Variables } from '../../types';

export function getDocumentName(document: string): string {
  const node = gql`
    ${document}
  `;
  const nodeDef = node.definitions.find(
    d => d.kind === 'OperationDefinition'
  ) as OperationDefinitionNode | undefined;

  const name = nodeDef?.name?.value;
  if (!name) {
    throw Error('Unable to get document name');
  }

  return name;
}

export function getOperationName(op: Operation) {
  const opDef = op.query.definitions.find(
    d => d.kind === 'OperationDefinition'
  ) as OperationDefinitionNode | undefined;
  if (opDef) {
    return opDef.name?.value;
  }
}

/**
 * This parser makes the result transferable by:
 * - parsing properties of urql's CombinedError, which is a class
 * - not passing response field, which isn't used and is also a class
 *
 * @param result
 * @returns
 */
export function parseResult<T extends keyof DisruptorServiceWorker>(
  result: OperationResult<GraphQLOutput<T>, Variables<T>>
): OperationResult<GraphQLOutput<T>, Variables<T>> {
  const { error } = result;

  if (!error) {
    return result;
  }

  return {
    ...result,
    error: {
      name: error.name,
      message: error.message,
      graphQLErrors: error.graphQLErrors,
      networkError: error.networkError
      // response: error.response
    }
  };
}
