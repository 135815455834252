import { loggers } from 'lib/log';
import { ClientError, Variables } from './types';
import { ExecutionResult } from '@urql/core';

/** An abstract observer-like interface.
 *
 * @remarks
 * Observer-like interfaces are passed to {@link ObservableLike.subscribe} to provide them
 * with callbacks for their events.
 *
 * @see {@link https://github.com/tc39/proposal-observable} for the full TC39 Observable proposal.
 */
interface ObserverLike<T> {
  /** Callback for values an {@link ObservableLike} emits. */
  next: (value: T) => void;
  /** Callback for an error an {@link ObservableLike} emits, which ends the subscription. */
  error: (err: any) => void;
  /** Callback for the completion of an {@link ObservableLike}, which ends the subscription. */
  complete: () => void;
}

const CONNECTION_INIT = 'connection_init';
const CONNECTION_ACK = 'connection_ack';
const PING = 'ping';
const PONG = 'pong';
const SUBSCRIBE = 'subscribe';
const NEXT = 'next';
const ERROR = 'error';
const COMPLETE = 'complete';

const log = loggers.service;

type MessagePayload = { [key: string]: any };

type SubscribePayload<V extends Variables = Variables, E = any> = {
  operationName?: string | null;
  query: string;
  variables?: V;
  extensions?: E;
};

class GraphQLWebSocketMessage<A = MessagePayload> {
  private _type: string;
  private _id?: string;
  private _payload?: A;

  public get type(): string {
    return this._type;
  }
  public get id(): string | undefined {
    return this._id;
  }
  public get payload(): A | undefined {
    return this._payload;
  }

  constructor(type: string, payload?: A, id?: string) {
    this._type = type;
    this._payload = payload;
    this._id = id;
  }

  public get text(): string {
    const result: any = { type: this.type };
    if (this.id != null && this.id != undefined) result.id = this.id;
    if (this.payload != null && this.payload != undefined)
      result.payload = this.payload;
    return JSON.stringify(result);
  }

  static parse<A>(
    data: string,
    f: (payload: any) => A
  ): GraphQLWebSocketMessage<A> {
    const { type, payload, id }: { type: string; payload: any; id: string } =
      JSON.parse(data);
    return new GraphQLWebSocketMessage(type, f(payload), id);
  }
}

export type SocketHandler = {
  onInit?: <T>() => Promise<T>;
  onAcknowledged?: <A>(payload?: A) => Promise<void>;
  onPing?: <In, Out>(payload: In) => Promise<Out>;
  onPong?: <T>(payload: T) => any;
  onClose?: () => any;
};

export type UnsubscribeCallback = () => void;

type SubscriptionRecord = {
  subscriber: ObserverLike<ExecutionResult>;
  query: string;
  variables?: Variables;
};

type SocketState = {
  acknowledged: boolean;
  lastRequestId: number;
  subscriptions: { [key: string]: SubscriptionRecord };
};

export class GraphQLWebSocketClient {
  static PROTOCOL: string = 'graphql-transport-ws';

  private socket: WebSocket;
  private socketState: SocketState = {
    acknowledged: false,
    lastRequestId: 0,
    subscriptions: {}
  };

  constructor(
    socket: WebSocket,
    { onInit, onAcknowledged, onPing, onPong }: SocketHandler
  ) {
    this.socket = socket;

    socket.onopen = async event => {
      log.debug('Websocket open', { event });
      this.socketState.acknowledged = false;
      this.socketState.subscriptions = {};
      socket.send(ConnectionInit(onInit ? await onInit() : null).text);
    };

    socket.onclose = event => {
      // NOTE: CloseEven may contain some clues as to reason for failure.
      // However, see specs for all instances in which code will be 1066
      // https://websockets.spec.whatwg.org//#eventdef-websocket-error
      log.info('Websocket closed', {
        name,
        closeEvent: {
          code: event.code,
          reason: event.reason,
          wasClean: event.wasClean,
          isTrusted: event.isTrusted,
          type: event.type
        }
      });
      this.socketState.acknowledged = false;
      this.socketState.subscriptions = {};
    };
    socket.onerror = event => {
      // NOTE: As per W3C specs, the error Event is generic and doesn't include much information
      // for security reasons. For debugging, use closeEvent information
      log.error(new Error('Graphql socket error'), { event });
    };

    socket.onmessage = e => {
      try {
        const message = parseMessage(e.data);
        switch (message.type) {
          case CONNECTION_ACK: {
            if (this.socketState.acknowledged) {
              log.warn('Duplicate CONNECTION_ACK message ignored');
            } else {
              this.socketState.acknowledged = true;
              if (onAcknowledged) onAcknowledged(message.payload);
            }
            return;
          }
          case PING: {
            if (onPing)
              onPing(message.payload).then(r => socket.send(Pong(r).text));
            else socket.send(Pong(null).text);
            return;
          }
          case PONG: {
            if (onPong) onPong(message.payload);
            return;
          }
        }

        if (!this.socketState.acknowledged) {
          // Web-socket connection not acknowledged
          return;
        }

        if (
          message.id === undefined ||
          message.id === null ||
          !this.socketState.subscriptions[message.id]
        ) {
          // No subscription identifer or subscription indentifier is not found
          return;
        }
        const { query, variables, subscriber } =
          this.socketState.subscriptions[message.id];

        switch (message.type) {
          case NEXT: {
            // pass full response, including errors
            subscriber.next(message.payload);
            return;
          }

          case ERROR: {
            subscriber.error(
              new ClientError(
                { errors: message.payload, status: 200 },
                { query, variables }
              )
            );
            return;
          }

          case COMPLETE: {
            subscriber.complete();
            // When this instance is closed, the onclose socket listener
            // will reset state. No need to delete here
            // delete this.socketState.subscriptions[message.id]
            this.close();
            return;
          }
        }
      } catch (error) {
        // Unexpected errors while handling graphql-ws message
        log.error(
          new Error('Unexpected errors while handling graphql-ws message'),
          { error }
        );
        socket.close(1006);
      }
      socket.close(4400, 'Unknown graphql-ws message.');
    };
  }

  private makeSubscribe(
    query: string,
    operationName: string | undefined,
    subscriber: ObserverLike<ExecutionResult>,
    variables?: Variables
  ): UnsubscribeCallback {
    const subscriptionId = (this.socketState.lastRequestId++).toString();
    this.socketState.subscriptions[subscriptionId] = {
      query,
      variables,
      subscriber
    };
    this.socket.send(
      Subscribe(subscriptionId, { query, operationName, variables }).text
    );
    return () => {
      this.socket.send(Complete(subscriptionId).text);
      // Subscription state will be handled when ws is closed
      // If subscription is deleted now, then the subscription's complete hook will never be called
      // when the client is the party completing the connection
      // delete this.socketState.subscriptions[subscriptionId]
    };
  }

  // rawRequest(query: string, variables?: Variables): Promise<ExecutionResult> {
  //   return new Promise<ExecutionResult>((resolve, reject) => {
  //     let result: ExecutionResult;
  //     this.rawSubscribe(
  //       query,
  //       {
  //         next: nextResult => (result = nextResult),
  //         error: reject,
  //         complete: () => resolve(result)
  //       },
  //       variables
  //     );
  //   });
  // }

  // request(
  //   query: string,
  //   operationName: string,
  //   variables?: Variables
  // ): Promise<ExecutionResult> {
  //   return new Promise<ExecutionResult>((resolve, reject) => {
  //     let result: ExecutionResult;
  //     this.subscribe(
  //       query,
  //       operationName,
  //       {
  //         next: (data: ExecutionResult) => (result = data),
  //         error: reject,
  //         complete: () => resolve(result)
  //       },
  //       variables
  //     );
  //   });
  // }

  subscribe(
    query: string,
    operationName: string,
    subscriber: ObserverLike<ExecutionResult>,
    variables?: Variables
  ): UnsubscribeCallback {
    return this.makeSubscribe(query, operationName, subscriber, variables);
  }

  // rawSubscribe(
  //   query: string,
  //   subscriber: ObserverLike<ExecutionResult>,
  //   variables?: Variables
  // ): UnsubscribeCallback {
  //   return this.makeSubscribe(query, undefined, subscriber, variables);
  // }

  ping(payload: Variables) {
    this.socket.send(Ping(payload).text);
  }

  close() {
    if (
      this.socket.readyState !== this.socket.CLOSING &&
      this.socket.readyState !== this.socket.CLOSED
    ) {
      this.socket.close(1000);
    }
  }
}

// Helper functions

function parseMessage<A = any>(
  data: string,
  f: (payload: any) => A = a => a
): GraphQLWebSocketMessage<A> {
  const m = GraphQLWebSocketMessage.parse<A>(data, f);
  return m;
}

function ConnectionInit<A>(payload?: A) {
  return new GraphQLWebSocketMessage(CONNECTION_INIT, payload);
}

function Ping(payload: any) {
  return new GraphQLWebSocketMessage(PING, payload, undefined);
}
function Pong(payload: any) {
  return new GraphQLWebSocketMessage(PONG, payload, undefined);
}

function Subscribe(id: string, payload: SubscribePayload) {
  return new GraphQLWebSocketMessage(SUBSCRIBE, payload, id);
}

function Complete(id: string) {
  return new GraphQLWebSocketMessage(COMPLETE, undefined, id);
}
