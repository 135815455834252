import { Exchange, mapExchange } from '@urql/core';
import { getDocumentName, getOperationName, parseResult } from '../lib/ops';
import * as GqlSchema from '../../../backend/graphql/gql';
import { AppEventTarget } from 'lib/events/globalEvents';
import {
  AppEventName,
  LoggedInMethod,
  ReissueReason
} from 'lib/events/contracts';
import { AppErrorName } from 'lib/events/errors';
import { loggers } from 'lib/log';
import { assertUnreachable } from 'lib/util';

const log = loggers.service;

export const workerEventTarget = new AppEventTarget();

function handleTokenResultFailure(reason: GqlSchema.TokenFailure) {
  switch (reason) {
    case 'ALREADY_USED':
      workerEventTarget.dispatch(AppEventName.MagicLinkReissued, {
        reason: ReissueReason.AlreadyUsed
      });
      break;
    case 'EXPIRED':
      workerEventTarget.dispatch(AppEventName.MagicLinkReissued, {
        reason: ReissueReason.Expired
      });
      break;
    case 'UNSPECIFIED':
      log.error(
        new Error(
          'Unexpected Unspecified for MagicLinkReissueReason. Assuming expired'
        )
      );
      break;
    default:
      assertUnreachable(reason);
  }
}

function handleCartCustomerDetails({
  details
}: GqlSchema.SetCartCustomerDetailsMutationVariables) {
  const acceptedPolicies =
    details.acceptedPrivacyPolicy && details.acceptedTermsAndConditions;
  if (acceptedPolicies) {
    workerEventTarget.dispatch(AppEventName.UserAcceptedPolicies);
  }
  workerEventTarget.dispatch(AppEventName.SetCartCustomerDetails, details);
}

function handleNewSupportTicket({
  enquiry
}: GqlSchema.OpenSupportTicketMutationVariables) {
  const { email } = enquiry;
  const acceptedPolicies =
    enquiry.acceptedPrivacyPolicy && enquiry.acceptedTermsAndConditions;

  if (acceptedPolicies) {
    workerEventTarget.dispatch(AppEventName.UserAcceptedPolicies);
  }

  workerEventTarget.dispatch(AppEventName.CreatedTicket, { email });
}

const eventsExchange = (): Exchange =>
  mapExchange({
    // onOperation(operation) {
    //   log.info('URQL GraphQL operation', operation as Record<string, any>);
    // },
    onResult(result) {
      if (result.operation.kind === 'mutation') {
        const { data, error, operation } = parseResult(result as any);

        const opName = getOperationName(operation);
        if (data) {
          /**
           * Checkout
           */
          if (
            opName === getDocumentName(GqlSchema.SetCartCustomerDetailsDocument)
          ) {
            const variables = result.operation
              .variables as GqlSchema.SetCartCustomerDetailsMutationVariables;
            handleCartCustomerDetails(variables);
          }

          if (
            opName ===
            getDocumentName(
              GqlSchema.SetCartCustomerDetailsAndSubscribeDocument
            )
          ) {
            const variables = result.operation
              .variables as GqlSchema.SetCartCustomerDetailsAndSubscribeMutationVariables;
            handleCartCustomerDetails(variables);
            workerEventTarget.dispatch(AppEventName.SubscribedNewsletter, {
              email: variables.details.email
            });
          }
          /**
           * Marketing
           */
          if (
            opName === getDocumentName(GqlSchema.SubscribeToNewsletterDocument)
          ) {
            const variables = result.operation
              .variables as GqlSchema.SubscribeToNewsletterMutationVariables;

            workerEventTarget.dispatch(AppEventName.SubscribedNewsletter, {
              email: variables.subscription.email
            });
          }
          /**
           * Support
           */
          if (opName === getDocumentName(GqlSchema.OpenSupportTicketDocument)) {
            const variables = result.operation
              .variables as GqlSchema.OpenSupportTicketMutationVariables;
            handleNewSupportTicket(variables);
          }
          if (
            opName ===
            getDocumentName(
              GqlSchema.OpenSupportTicketAndSubscribeNewsletterDocument
            )
          ) {
            const variables = result.operation
              .variables as GqlSchema.OpenSupportTicketAndSubscribeNewsletterMutationVariables;
            handleNewSupportTicket(variables);
            workerEventTarget.dispatch(AppEventName.SubscribedNewsletter, {
              email: variables.enquiry.email
            });
          }
          /**
           * User
           */
          if (opName === getDocumentName(GqlSchema.AuthenticateDocument)) {
            const user = (data as unknown as GqlSchema.AuthenticateMutation)
              .authenticate;
            workerEventTarget.dispatch(AppEventName.Authenticated, user);
          }

          if (opName === getDocumentName(GqlSchema.LogOutDocument)) {
            const user = (data as GqlSchema.LogOutMutation).logout;
            workerEventTarget.dispatch(AppEventName.LoggedOut, user);
          }

          if (
            opName ===
            getDocumentName(GqlSchema.ExchangeLoginTokenDirectDocument)
          ) {
            const sessionUser = (
              data as GqlSchema.ExchangeLoginTokenDirectMutation
            ).exchangeDirectToken.nextUser;
            workerEventTarget.dispatch(AppEventName.LoggedIn, {
              method: LoggedInMethod.DirectToken,
              sessionUser
            });
          }

          if (
            opName === getDocumentName(GqlSchema.ExchangeLoginMagicLinkDocument)
          ) {
            const exchangeMagicLink = (
              data as GqlSchema.ExchangeLoginMagicLinkMutation
            ).exchangeMagicLink;
            switch (exchangeMagicLink.__typename) {
              case 'MagicLinkExchange':
                workerEventTarget.dispatch(AppEventName.LoggedIn, {
                  method: LoggedInMethod.EmailToken,
                  sessionUser: exchangeMagicLink.nextUser
                });
                break;
              case 'TokenResultFailure':
                handleTokenResultFailure(exchangeMagicLink.reason);
                break;
            }

            if (
              opName === getDocumentName(GqlSchema.ConfirmEmailAddressDocument)
            ) {
              const confirmEmailAddress = (
                data as GqlSchema.ConfirmEmailAddressMutation
              ).confirmEmailAddress;

              switch (confirmEmailAddress.__typename) {
                case 'UserActionComplete':
                  workerEventTarget.dispatch(AppEventName.EmailConfirmed);
                  workerEventTarget.dispatch(AppEventName.LoggedIn, {
                    method: LoggedInMethod.EmailConfirmation,
                    sessionUser: confirmEmailAddress.nextUser
                  });
                  break;
                case 'TokenResultFailure':
                  handleTokenResultFailure(confirmEmailAddress.reason);
                  break;
              }
            }

            if (
              opName ===
              getDocumentName(GqlSchema.ConfirmNewsletterDiscountDocument)
            ) {
              const variables = result.operation
                .variables as GqlSchema.ConfirmNewsletterDiscountMutationVariables;
              const {
                exchangeSystemToken,
                claimDiscount,
                confirmNewsletterSubscription
              } = data as GqlSchema.ConfirmNewsletterDiscountMutation;
              switch (exchangeSystemToken.__typename) {
                case 'UserActionComplete': {
                  workerEventTarget.dispatch(AppEventName.LoggedIn, {
                    method: LoggedInMethod.Discount,
                    sessionUser: exchangeSystemToken.nextUser
                  });
                  break;
                }
                case 'TokenResultFailure': {
                  handleTokenResultFailure(exchangeSystemToken.reason);
                  break;
                }
              }

              if (claimDiscount.__typename === 'ClaimedDiscount') {
                workerEventTarget.dispatch(AppEventName.ClaimedDiscount, {
                  cart: claimDiscount.cart,
                  email: variables.claim.email
                });
              }

              if (confirmNewsletterSubscription) {
                workerEventTarget.dispatch(AppEventName.ConfirmedNewsletter, {
                  email: variables.confirmation.email
                });
              }
            }
          }
        }

        if (error) {
          if (opName === getDocumentName(GqlSchema.LogOutDocument)) {
            workerEventTarget.dispatchError(AppErrorName.LogOutError, {
              error
            });
          }
        }
      }
    }
    // onError(_error, _operation) {}
  });

export default eventsExchange;
