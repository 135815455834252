import * as GqlSchema from '../../backend/graphql/gql';

import {
  Client,
  Exchange,
  fetchExchange,
  subscriptionExchange
} from '@urql/core';

import authenticationState from './authenticationState';
import { cacheExchangeOptimistic } from './cache/optimistic';
import { cacheExchangeUpdates } from './cache/updates';
import eventsExchange from './exchanges/events';
import { loggers } from 'lib/log';
import { authExchange as makeAuthExchange } from '@urql/exchange-auth';
import { cacheExchange as makeCacheExchange } from '@urql/exchange-graphcache';
import makeRetryExchange from './exchanges/retry';
import { makeWsClient } from '@svelte/service/backend/graphql/client/ws';
import monitorExchange from './exchanges/monitor';
import refocusExchange from './exchanges/refocus';
// import { devtoolsExchange } from '@urql/devtools';
import { requestPolicyExchange } from '@urql/exchange-request-policy';
import schema from '../../backend/graphql/introspection.json';

const log = loggers.service;

const cacheExchange = makeCacheExchange({
  keys: {
    // user keys need to be static
    // so that the right data is sent to
    // session-dependent queries after log in/out
    UserProfile: () => 'UserProfile',
    SessionUser: () => 'SessionUser',
    OrderItem: () => null,
    OrderAmounts: () => null,
    Location: () => null
  },
  schema: process.env.GATSBY_ACTIVE_ENV === 'development' ? schema : undefined,
  updates: cacheExchangeUpdates,
  optimistic: cacheExchangeOptimistic
});

const authExchange = makeAuthExchange(async utils => {
  return {
    addAuthToOperation: operation => operation,
    willAuthError: () => {
      if (!authenticationState.authenticated) {
        log.info('Authenticating session');
        return true;
      }
      return false;
    },
    didAuthError: _error => false,
    refreshAuth: async () => {
      try {
        log.info('Refreshing authentication');
        const authResponse = await utils.mutate<
          GqlSchema.AuthenticateMutation,
          GqlSchema.AuthenticateMutationVariables
        >(GqlSchema.AuthenticateDocument, {});
        if (authResponse.data) {
          log.info('Session authenticated', { sessionUser: authResponse.data });
          authenticationState.authenticate();
        }
      } catch (error) {
        log.error(new Error('Unable to authenticate'), { error });
      }
    }
  };
});

const exchanges: Exchange[] = [
  refocusExchange(),
  eventsExchange(),
  monitorExchange(),
  requestPolicyExchange({}),
  // auth has to be before cache, so that queries don't
  // try to read cache before authentication (and cache update)
  // has finished. Otherwise, there will be race condition
  // and query will be done over the network
  authExchange,
  cacheExchange,
  // Note the position of the retryExchange - it should be placed prior to the
  // fetchExchange and after the cacheExchange for it to function correctly
  makeRetryExchange(),
  fetchExchange,
  subscriptionExchange({
    forwardSubscription(request, _operation) {
      // const input = { ...request, query: request.query || '' };
      return {
        subscribe(sink) {
          let shouldSubscribe = true;
          let unsubscribe = () => {
            shouldSubscribe = false;
          };

          makeWsClient().then(client => {
            if (shouldSubscribe) {
              log.info('Starting URQL new subscription', {
                name: request.operationName
              });
              unsubscribe = client.subscribe(
                request.query || '',
                request.operationName || '',
                sink,
                request.variables
              );
            }
          });

          return { unsubscribe };
        }
      };
    }
  })
];

// NOTE: Webpack is not clever enough to drop devtoolsExchange
// if (process.env.GATSBY_URQL_DEBUG === 'true') {
//   // eslint-disable-next-line no-console
//   console.info('Running URQL in debug mode');
//   exchanges.unshift(devtoolsExchange);
// }

export const backendClient = new Client({
  url: process.env.GATSBY_GRAPHQL_ENDPOINT as string,
  exchanges,
  fetchOptions: {
    credentials: 'include'
  }
});

// if (client.subscribeToDebugTarget) {
//   client.subscribeToDebugTarget(event => {
//     if (event.source === 'cacheExchange') {
//       console.log('cache event: ', event);
//     }
//   });
// }
